@import "./colors";

:root {
  --menubar-collapsed-width: 74px; // collapsed width
  --menubar-expanded-width: 200px; // expanded width
  --margin: 20px;
}

$border-radius: (
  extra-small: 4px,
  small: 8px,
  medium: 16px,
  large: 24px,
);

$menubar-height: 26px;
$menubar-width: 250px;
$side-tabs-width: 258px;
$side-tabs-width-tablet: 162px;
$bounded-charts-height: 240px;
$ranking-width: 264px;
// account page
$account-input-wrapper-color: #1b212c;
$account-background-size: cover;
$account-button-color: #008b32;
$account-default-btn-color: #ffffff;
$account-type-active: #008b32;

// sign in page
$sign-in-bg-color: #1b212c;
$sign-in-input-color: #1b212c;
$sign-in-text-color: #f3f3f6;
$sign-in-input-label-color: #f3f3f6;
$sign-in-textfield-bg-color: #353943;
$sign-in-textfield-border-color: #008b32;
$sign-in-textfield-placeholder: #6b7282;
$sign-in-button-color: #008b32;
$sign-in-button-disabled-bg-color: #4f624c;
$sign-in-button-disabled-text-color: #9daca2;
$sign-in-remember-me-label-color: #b5bbc8;
$sign-in-error-color: #981c1b;

// forgot password page
$forgot-password-text-color: #b5bbc8;
$forgot-password-bg-color: #13171d;

// home page
$account-btn-bg: rgba(0, 139, 50, 0.2);

// Add comparison list
$add-comparison-list-bg: #1b212c;
$add-comparison-text-color: #8c93a6;

// Chart CSS
$chart-bg-color: #191e27;
$chart-border-color: #2e3748;

// Search Cyclist
$search-cyclist-bg: linear-gradient(92.29deg, #a7e5be -263.03%, #008b32 104.3%);
$menu-item-bg-color: #212835;
$selected-menu-item: #ffffff;
$selected-sub-menu-item: #008b32;

$bottom-border-color: #39465f;

$list-edit-btn-bg: #263042;

$border-color: #2e3748;
$input-border-color: #404c54;
$panel-color: #191e27;
$popup-color: #1e2431;
$card-color: #212835;
$menu-color: #1b212c;
$hover-color: #323b50;
$performance-ranking-color: #f0f4f8;
$dropdown-color: #d7dee9;
$dropdown-hover: linear-gradient(91.92deg, #ccd8e3 -3.89%, #d7e0e9 98.68%),
  linear-gradient(91.92deg, #eaf1ff -3.89%, #ffffff 98.68%);
$calendar-bg-color: #2d3546;
$dropdown-text-color: #13171d;
$main-bg-color: #13171d;
$gray-bg-color: #8c93a6;

$primary-text-color: #ffffff;
$secondary-text-color: #8c93a6;

$accent-primary: #008b32;
$accent-secondary: #d71a21;

$error-text: #ff565f;

$upper-value-color: #ff616a;

$card-background-color: #fcfcfd;
$card-border-color: #e4e7ec;
$card-border-radius: map-get($border-radius, medium);

$menstrual-cycle-calendar-head-cell-size: 2.25rem;

$menstrual-cycle-calendar-cell-width: 2.75rem;
$menstrual-cycle-calendar-cell-height: 2.625rem;
$menstrual-cycle-calendar-cell-padding: 0.125rem;

$menstrual-cycle-calendar-cell-border-width: 1px;
$menstrual-cycle-calendar-cell-border-color: $card-border-color;
$menstrual-cycle-calendar-cell-border-radius: 0.5rem;

$menstrual-cycle-calendar-background-color: #f4f3ff;
$menstrual-cycle-calendar-accent-color: #6938ef;

// skeleton
$primary-gray-100: #f2f4f7;
$primary-gray-200: $card-border-color;

// dropdown bg
$dropdown-bg: #353943;

// chat suggestion bg
$chat-suggestion-bg: #353943;
$chat-suggestion-color: #ffffff;
$chat-text-color: #f3f3f6;
$chat-textfield-bg: #212835;
$chat-textfield-placeholder: #6b7282;
$chat-bubble-system-bg: #008b32;
$chat-bubble-user-bg: #212835;
$chat-input-wrapper-color: #1b212c;
$chat-body-bg: #353943;
$chat-body-icon-bg: #262b36;
$chat-window-border-color: #464b58;
$chat-window-header-bg: #212835;
$chat-feedback-color: #6b7282;
$chat-dot-icon-bg: #1b212c;

// athlete card
$athlete-card-color: #f3f3f6;
$athlete-card-group-color: #8c93a6;
$athlete-card-info-color: #b5bbc8;
$athlete-card-bg-color: #1b212c;
$athlete-avatar-border-color: #464b58;

// Group modification screen
$background-text-field: #353943;

$radar-selector-button-active: #ffffff;

// Team Power Profile
$dropdown-border-color: #008b32;
$tooltip-font-color: #ffffff;
$dropdown-bg-color: transparent;

// Change password
$change-password-input-color: #353943;

$pop-up-bg-color: #13171d;

// Calendar
$pop-up-calendar-bg-color: #212835;
$pop-up-calendar-day-color: #b5bbc8;
$pop-up-calendar-text-field-label-color: #b5bbc8;
$pop-up-calendar-selected-day-color: #f3f3f6;
$pop-up-calendar-selected-day-bg-color: #008b32;
$pop-up-calendar-disabled-day-color: rgba(140, 147, 166, 0.2);
$pop-up-calendar-week-color: #8c93a6;

// icon colos
$icon-color: #ffffff;

// performance management chart tooltip
$performance-management-tooltip-text: #ffffff;
$performance-management-tooltip-title: #8c93a6;
$tsbLow: #ff0000;
$tsbHigh: #ff8c00;

// menubar header
$menubar-gradient: #008b32;
$unselected-menu-item: #6b7282;

// tooltip color
$tooltip-bg-color: #f3f3f6;
$tooltip-text-color: #13171d;
$tooltip-border-color: #f3f3f6;

$clear-all-color: #ffffff;

// all comment
$warning: rgba(178, 171, 0, 0.5);
$submitAllButtonColor: #f3f3f6;
$previousDisabledBackgroundColor: rgba(165, 220, 185, 0.1);
$disabledBackgroundColor: rgba(165, 220, 185, 0.5);
$previousDisabledBorder: 1px solid #9daca2;
$previousDisabledColor: #9daca2;
$rectangularDivBackgroundColor: #353943;

// coach coimment popup
$detail-label-color: #8c93a6;
$detail-value-color: #f3f3f6;
$detail-odd-color: #1b212c;
$detail-even-color: #212835;

$primary-input-text-color: #ffffff;
$comment-button-disabled-bg-color: #8c93a6;

$primary-card-text-color: #ffffff;
$card-background-color: #1b212c;
$card-details-bg-color: #212835;
$card-item-title: #6b7282;
$card-item-unit: #6b7282;
$card-item-value: #ffffff;
$card-corner-color: rgba(27, 33, 44, 0.7);
$card-vertical-dots-color: #ffffff;

$popup-bg-dropdown: #353943;
$popup-border: none;
$popup-color-dropdown: #8c93a6;
$popup-text-color-dropdown: white;
$popup-border-color: none;
$popup-drop-items-color: #f3f3f6;
$popup-bgcolor-dropdown: #008b32;
$popup-hover-drop: white;
$drop-bg: #353943;
$drop-options-color: white;
$popup-select-drop: #212835;

$react-select-focus-color: #fff;
$react-select-selected-text-color: #fff;
$react-select-surface-color: #353943;
$react-select-surface-hover: #212835;
$react-select-option-color: #fff;

$close-button-fill-color: #fff;
$dropdown-selected-value-color: #fff;
$medical-table-icon-color: #dadde3;

// chat feedback colors
$chat-feedback-btn-fill-color: #fff;
$chat-feedback-btn-stroke-color: #565b68;
